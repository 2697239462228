import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #2E2E2E;
    --white: #fff;
    --gray: #cc5200;
    --orange: #FF6600;
    --red: #A6192E;
  }
  html {
    font-size: 10px;
  }

  body {
    background: var(--orange);
    font-size: 1.5rem;
    color: white;
  }

  h2 {
      font-size: 3rem;
  }

  a {
    color: white;
    text-decoration: none;
    &:hover {
      color: var(--red);
    }
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--orange) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  img {
    max-width: 100%;
  }

`;

export default GlobalStyles;
