import React from 'react';
import styled from 'styled-components';
import 'normalize.css';
import Banner from './Banner';
import Nav from './Nav';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import { Divider } from './Divider';

const SiteBorderStyles = styled.div`
  max-width: 888px;
  margin: 0 auto 4rem auto;
  padding: 5px;
  padding: clamp(5px, 1vw, 25px);
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <SiteBorderStyles>
        <Banner />
        <Nav />
        <Divider />
        {children}
        <Divider />
        <Footer />
      </SiteBorderStyles>
    </>
  );
}
