import React from 'react';
import { Link } from 'gatsby';
import BannerImage from '../assets/images/banner.jpg';

export default function Banner() {
  return (
    <Link to="/">
      <img
        src={BannerImage}
        alt="Huddle House Fan Club banner"
        style={{ display: 'block' }}
      />
    </Link>
  );
}
