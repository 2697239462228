import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const FooterStyles = styled.nav`
  padding: 1rem 0 0;
  ul {
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
  }
  li {
    --rotate: -2deg;
    transform: rotate(var(--rotate));
    order: 1;
    display: inline-block;
    &:nth-child(1) {
      --rotate: 2deg;
    }
    &:nth-child(2) {
      --rotate: -2.5deg;
    }
    &:nth-child(3) {
      --rotate: 2deg;
    }
    &:hover {
      --rotate: 3deg;
    }
  }
  a {
    font-size: 2.5rem;
    color: white;
    text-decoration: none;
    display: block;
    &:hover {
      color: var(--red);
    }
    @media (max-width: 800px) {
      font-size: 2rem;
    }
  }
`;

export default function Footer() {
  return (
    <footer>
      <FooterStyles>
        <div>
          <ul>
            <li>
              <Link to="/checklist">Photo Checklist</Link>
            </li>
            <li style={{ marginLeft: '2rem' }}>
              <Link to="/souvenirs">Souvenirs</Link>
            </li>
            {/* <li>
              <Link to="/site-news">Site News</Link>
            </li> */}
          </ul>
        </div>
      </FooterStyles>
      <p className="center" style={{ fontSize: '1.5rem' }}>
        &copy; The Huddle House Fan Club 2000 - {new Date().getFullYear()}
      </p>
    </footer>
  );
}
